import React from "react"
import { graphql } from "gatsby"

export default ({ data }) => {
  console.log({data});
  return (
      <div>
      <h1>My Site Files</h1>
      <table>
      <thead>
        <tr>
          <th>relative path</th>
          <th>size</th>
          <th>extension</th>
          <th>birthTime</th>
        </tr>
      </thead>
      <tbody>
      {data.allFile.edges.map(({node}, index) => {
        return (
          <tr key={index}>
            <td>{ node.relativePath }</td>
            <td>{ node.prettySize }</td>
            <td>{ node.extension }</td>
            <td>{ node.birthTime }</td>
          </tr>
        );
       })
      }
      </tbody>
      </table>
    </div>
  );
};

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          relativePath
          prettySize
          extension
          birthTime(fromNow: true)
        }
      }
    }
  }
`;
